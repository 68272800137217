import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { capitalize } from 'src/util'
import { usePageContext } from 'src/context/PageContext'
import { useVisitorApi } from 'src/hooks/useApi'

export default function useUniqueSelectList(array, key) {
    const { t } = useTranslation();
    const extractArray = array.map(item => item[key]).flat();
    const uniqueList = [...new Set(extractArray)];
    let translatedList = uniqueList
        .sort()
        .map(item =>
        t(`findTeacher.filters.${key}`, { returnObject: true }).find(key => Object.keys(key)[0].toLowerCase() === item.toLowerCase())
    );

    let finalSelectList = translatedList.map(item => (
        {
            label: capitalize(Object.values(item).toString()),
            value: Object.keys(item).toString()
        })
    );

    return [
        ...finalSelectList
    ]
}

export function useGetTranslatedInstruments(array, key) {
    const { lang } = usePageContext();
    const extractArray = array.map(item => item[key]).flat();
    let uniqueList = [];
    const map = new Map();
    for ( let inst of extractArray) {
        if(!map.has(inst.id)) {
            map.set(inst.id, true) // set any value to map
            uniqueList.push({
                id: inst.id,
                translations: inst.translations
            })
        }
    }

    let finalList = uniqueList
        .map(item => ({ label: item.translations[lang], value: item.id }))
        .sort((a, b) => (a.label > b.label) ? 1 : -1)

    return [
        ...finalList
    ]
}
