import React, { useEffect, useState, useContext } from 'react'
import PlanLesson from 'src/components/sections/booking/PlanLesson'
import { Context as BookingContext } from 'src/context/BookingContext'
import Separator from 'src/components/atoms/Separator'

export default function ScheduleLessons() {
    const { state: { nrOfLessons, isTrial, lessonsToSchedule } , changeBooking } = useContext(BookingContext);
    const [openTimeSlot, setOpenTimeSlot] = useState(null);

    // Render the correct number of timeslots planning, depending on the nrOfLessons
    useEffect(() => {
        setOpenTimeSlot(null)
            let array = [];
            let finalListOfLessons;
            for(let i= 0; i < nrOfLessons; i++) {
                array.push({id: i, selectedDate: null, selectedTime: null, availabilityID: null})
            }

            finalListOfLessons = [...array]
            changeBooking({lessonsToSchedule: finalListOfLessons})

            /*let nrScheduledLessons = lessonsToSchedule.length;
            let theSurplus = +nrOfLessons - nrScheduledLessons;
            let extraLessons = [];
            let finalListOfLessons;
            if (Math.sign(theSurplus) === 1) {
                let acc = nrScheduledLessons;
                doTimes(theSurplus)(() => {
                    extraLessons.push({id: acc, selectedDate: null, selectedTime: null, availabilityID: null})
                    acc++
                });
                finalListOfLessons = [...lessonsToSchedule, ...extraLessons];
            } else {
                finalListOfLessons = lessonsToSchedule.slice(0, theSurplus)
            }

            changeBooking({lessonsToSchedule: finalListOfLessons})*/

    }, [nrOfLessons, isTrial])

    return (
        <div className="my-10">
            {/*{trial && <h3 className="text-2xl font-bold">Trial</h3>}*/}
            {lessonsToSchedule.map((lesson, index) => (
                <PlanLesson
                    lesson={lesson}
                    setOpenTimeSlotPicker={setOpenTimeSlot}
                    openTimeSlotPicker={openTimeSlot}
                    index={index}
                    key={lesson.id}/>
            ))}
            <Separator/>
        </div>
    )
}
