import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {navigate} from 'gatsby'
import {Context as ThemeContext} from 'src/context/ThemeContext'
import {Context as BookingContext} from 'src/context/BookingContext'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import MarketingLayout from 'src/components/layout/MarketingLayout'
import {useVisitorApi} from 'src/hooks/useApi'
import BookATeacherBanner from 'src/components/sections/booking/BookATeacherBanner'
import AddInstrumentsDuration from 'src/components/sections/booking/AddInstrumentsDuration'
import FixedFooter from 'src/components/sections/teacherProfile/FixedFooter'
import ScheduleLessons from 'src/components/sections/booking/ScheduleLessons'
import AddComments from 'src/components/sections/booking/AddComments'
import useNoTeachersAllowed from 'src/hooks/useNoTeachersAllowed'
import Error from 'src/components/forms/Error'

export default function BookATrial(props) {
    const {t} = useTranslation();
    const {setTheme} = useContext(ThemeContext);
    const {state, changeBooking} = useContext(BookingContext);
    const {id} = props;
    const {bookingDetailsReview} = useLocalizedRoutes();
    const [loading, setLoading] = useState(false);
    const {getSingleTeacher} = useVisitorApi();
    const [error, setError] = useState(false);

    useEffect(() => setTheme('inverse'), [])
    useNoTeachersAllowed()

    useEffect(() => {
        if (!state.teacher || state.teacher.id !== id) {
            getTeacher()
        }
    }, [id])

    function setDefaultBooking(teacher) {
        let instrument = teacher.instruments[0].id;
        let lesson = teacher.lesson_plans.find(({is_trial}) => is_trial)

        changeBooking({
            ...state,
            teacherID: id,
            instrument,
            teacher: teacher,
            duration: 60,
            lessonPlan: lesson.id,
            lessonsToSchedule: [
                {
                    id: 0,
                    selectedDate: null,
                    selectedTime: null,
                    availabilityID: null
                }
            ]
        })
    }

    async function getTeacher() {
        setLoading(true)
        let {data} = await getSingleTeacher(id)
        setLoading(false)
        setDefaultBooking(data.data)
    }

    function isValid() {
        return state.lessonsToSchedule.every(lesson => Object.values(lesson).every(key => key !== null))
    }

    function beginBooking() {
        if (isValid()) navigate(bookingDetailsReview)

        else setError(true)
    }

    function checkIfTeacher() {
        if (state.teacher) {
            return (
                <>
                    <BookATeacherBanner teacher={state.teacher}/>
                    <AddInstrumentsDuration/>
                    <ScheduleLessons trial/>
                    {error && <Error>Select a date and time for each lesson</Error>}
                    <AddComments/>
                    <FixedFooter
                        onClick={beginBooking}
                    />
                </>
            )
        }

        return 'No teacher found'
    }

    return (
        <MarketingLayout {...props} noFooter title={t('bookATeacher.title')}>
            <div className="container mx-auto px-6 lg:px-20">
                {checkIfTeacher()}
            </div>
        </MarketingLayout>
    )
}