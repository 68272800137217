import React, { useRef, useEffect } from 'react'
import classNames from 'classnames'
import { Error, Label } from 'src/components/forms'

const DEFAULT = classNames(
    'border border-solid border-grey-500 rounded mb-2 relative',
    'bg-white overflow-hidden flex items-stretch',
    'text-base font-normal',
);

const INPUT = classNames(
    'appearance-none w-full px-4 py-3 flex-1 outline-none h-32',
    'text-base font-normal',
);

const ENABLED = 'bg-white text-dark placeholder-grey-800';
const DISABLED = 'cursor-default bg-grey-100 text-grey-400 placeholder-grey-400';
const BOLD = 'font-medium text-lg leading-none text-center';

export function InnerTextArea(props) {
    const {
        name,
        id,
        value,
        isRequired,
        isDisabled,
        isFocused,
        placeholder,
        conditionalClasses,
        onChange,
        onFocus,
        onPaste,
        maxCount,
        defaultValue
    } = props;
    const ref = useRef(null);

    useEffect(function focusInput() {
        if (isFocused) {
            ref.current.focus();
        }
    }, [ref, isFocused]);

    return (
        <textarea
            ref={ref}
            className={classNames(INPUT, conditionalClasses)}
            name={name}
            id={id}
            value={value}
            placeholder={placeholder}
            required={isRequired}
            disabled={isDisabled}
            onChange={onChange}
            onFocus={onFocus}
            onPaste={onPaste}
            maxLength={maxCount}
            defaultValue={defaultValue}
        />
    );
}

export function TextAreaContainer(props) {
    const {
        id,
        name,
        isDisabled,
        isBold,
        label,
        error,
        className,
        renderInnerInput,
        isRequired,
        maxCount,
        value,
    } = props;
    const conditionalClasses = {
        [ENABLED]: !isDisabled,
        [DISABLED]: isDisabled,
        [BOLD]: isBold,
    };

    return (
        <>
            {label && <Label required={isRequired} field={id}>{label}</Label>}
            <div className={classNames(DEFAULT, className, conditionalClasses)}>
                {renderInnerInput({ ...props, conditionalClasses })}
                {maxCount && <span className="absolute bottom-0 right-0 mb-1 mr-2 text-xs text-grey-500">{maxCount - (value ? value.length : 0)}</span>}
            </div>
            <Error>{error}</Error>
        </>
    );
}

export default function TextArea(props) {
    return (
        <TextAreaContainer
            {...props}
            renderInnerInput={(props) => (
                <InnerTextArea {...props} />
            )}
        />
    );
}

TextArea.defaultProps = TextAreaContainer.defaultProps = {
    type: 'text',
    isRequired: false,
    isDisabled: false,
    isFocused: false,
    isBold: false,
    placeholder: undefined,
    error: undefined,
    label: undefined,
    renderPrefix: undefined,
    renderSuffix: undefined,
    className: undefined,
    onFocus: undefined,
    onPaste: undefined,
    maxCount: undefined
};
