import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Context as BookingContext } from 'src/context/BookingContext'
import { useGetTranslatedInstruments } from 'src/hooks/useUniqueSelectList'
import SelectInput from 'src/components/forms/Select'
import Separator from 'src/components/atoms/Separator'

const ROW = 'flex justify-between md:items-center py-4 flex-col md:flex-row';
const LABEL = 'text-lg md:text-xl md:font-bold mb-3 md:mb-0';



export default function AddInstrumentsDuration({numberOfLessons, durationQuery, isTrial}) {
    const { t } = useTranslation();
    const duration = [
        { label: `1 ${t('general.time.hour')}`, value: 60 },
        { label: `30 ${t('general.time.minutes')}`, value: 30 }
    ];
    const { state: { teacher, lessonsToSchedule}, changeBooking } = useContext(BookingContext);
    const instruments = useGetTranslatedInstruments([teacher], "instruments");
    const [durationEnabled, setDurationEnabled] = useState(duration[0])

    useEffect(() => {
        const { lesson_plans } = teacher;
        const plan = lesson_plans.find((lessonPlan) => (lessonPlan.lesson_amount === parseInt(numberOfLessons)) && (lessonPlan.duration === durationQuery ? parseInt(durationQuery) : 30));
        changeDuration(plan ? (plan.lesson_duration_minutes <= 30 ? 30 : 60) : 30)
    }, [numberOfLessons, durationQuery])

    function changeDuration(value) {
        setDurationEnabled(duration[duration.findIndex((obj) => obj.value === value)])
        changeBooking({duration: value, instrument: teacher.instruments[0].id})
        resetSchedules()
    }

    function resetSchedules() {
        let resettedValues = lessonsToSchedule.map(lesson => ({
            id: lesson.id,
            selectedDate: null,
            selectedTime: null,
            availabilityID: null
        }));

        changeBooking({lessonsToSchedule: resettedValues})
    }

    return (
        <>
            <div className="my-10">
                <div className={classNames(ROW)}>
                    <p className={classNames(LABEL)}>{t('bookATeacher.instrument')}</p>
                    <div className="w-full md:w-56">
                        <SelectInput
                            id="instruments"
                            name="instruments"
                            data={instruments}
                            defaultValue={instruments[0]}
                            onChange={({value}) =>
                                changeBooking({instrument: value})
                            }
                            isFormSelect
                        />
                    </div>
                </div>
                <div className={classNames(ROW)}>
                    <p className={classNames(LABEL)}>{t('bookATeacher.sessionDuration')}</p>
                    <div className="w-full md:w-56">
                        <SelectInput
                            id="duration"
                            name="duration"
                            data={duration}
                            value={durationEnabled}
                            onChange={({value}) =>
                                changeDuration(value)
                            }
                            isFormSelect
                        />
                    </div>
                </div>
            </div>
            <Separator/>
        </>
    )
}
