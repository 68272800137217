import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import TextArea from 'src/components/forms/Textarea'
import { Context as BookingContext } from 'src/context/BookingContext'

export default function AddComments({ booking, dispatchBooking}) {
    const { t } = useTranslation();
    const { state: { comments }, changeBooking } = useContext(BookingContext);

    return (
        <div className="my-10 md:pb-24">
            <p className="text-xl w-2/3 md:w-full mb-5">{t('bookATeacher.commentsTitle')}</p>
            <div className="md:w-1/2">
                <TextArea
                    value={comments}
                    onChange={e => changeBooking({
                        comments: e.target.value
                    })}
                    placeholder={t('bookATeacher.yourComments')}
                />
            </div>
        </div>
    )
}
